<template lang="pug">
ui-dialog(
  modal
  closable
  width="520px"
  header="Создать карточку контракта согласно заявке?"
  :visible-prop="show"
  :z-index="3"
  @content:hide="hide"
)
  template(#content)
    .flex-row
      cost-input.half(label="Цена контракта" v-model="contractCost" placeholder="Цена контракта")
      form-input.half(disabled v-model="cost.currency.alpha" label="Валюта" placeholder="Валюта")
    .flex-row
      date-picker(v-model="datePlanContract" label="Плановая дата" format="DD.MM.YYYY")
      date-picker(v-model="dateFactContract" label="Фактическая дата" format="DD.MM.YYYY")
    manager-selector(:show-set-me="!isTmManager" v-model="responsiblePerson" label="Ответственный")
  template(#actions)
    ui-button(type="secondary" @click.stop="hide") Отменить
    ui-button(@click.stop="createContract") Создать
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import { useApi } from "@/use/api/useApi";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";

import CostInput from "@/components/ui/form/input/CostInput.vue";
import FormInput from "@/components/ui/form/input/FormInput.vue";
import ManagerSelector from "@/components/selector/ManagerSelector.vue";
import DatePicker from "@/components/ui/picker/DatePicker.vue";
import UiDialog from "@/components/ui/dialog/UiDialog.vue";
import UiButton from "@/components/ui/button/UiButton.vue";

import type { PropType } from "vue";
import type { CreateContractI } from "@/use/api/ApiInterface";
import type { CostItemI } from "@/stores/manuals/ManualsInterface";
import type { ManagerI } from "@/stores/auth/UserInterface";

export default defineComponent({
  name: "CreateContractDialog",
  components: {
    UiButton,
    UiDialog,
    DatePicker,
    ManagerSelector,
    CostInput,
    FormInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    responsible: {
      type: Object as PropType<ManagerI>,
      default: null,
    },
    cost: {
      type: Object as PropType<CostItemI>,
      default: () => ({ currency: {} }),
    },
    lotId: {
      type: Number,
      default: null,
    },
  },
  emits: [
    "update:show",
    "on:hide",
  ],
  setup(props, context) {

    const { show } = toRefs(props);

    const datePlanContract = ref()
    const dateFactContract = ref()

    const contractCost = ref<number>(props.cost?.cost)
    const responsiblePerson = ref<ManagerI|null>(props.responsible || null);

    const { isTmManager } = useUserAccess();
    const { assignResourceId } = useSuperAccount();

    function hide() {
      context.emit('update:show', false);
      context.emit('on:hide');
      contractCost.value = 0;
      datePlanContract.value = null;
      dateFactContract.value = null;
    }

    watch(show,() => {
      if (show.value) {
        contractCost.value = props.cost?.cost;
        responsiblePerson.value = props.responsible || null;
      }
    })

    const { showSuccess, showError } = useNotificationsStore();

    function createContract() {
      const data : CreateContractI = assignResourceId({
        lot: `/api/lots/${props.lotId}`,
        status: "/api/contracts-statuses/1",
        currency: props.cost?.currency?.id,
        cost: contractCost.value,
        responsiblePerson: responsiblePerson.value ? `/api/users/${responsiblePerson.value.id}` : null,
        dates: (datePlanContract.value || dateFactContract.value) ? {
          datePlan: datePlanContract.value || undefined,
          dateFact: dateFactContract.value || undefined,
        } : undefined,
      })

      hide();

      useApi().contracts.postContract(data)
        .then(() => {
          showSuccess('Контракт успешно создан. Перейдите в раздел "Контракты", чтобы продолжить работу')
        })
        .catch((error) => {
          if (error?.response?.status === 412) showError('Контракт уже существует. Перейдите в раздел "Контракты", чтобы продолжить работу')
          else showError()
        })
    }

    return {
      hide,
      isTmManager,
      createContract,
      responsiblePerson,
      contractCost,
      datePlanContract,
      dateFactContract,
    }
  }
})
</script>

<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-flow: row;
  gap: 8px;

  .half {
    width: 50%;
  }
}
</style>
